const trackError = (error: Error, info: { componentStack: string }) => {
  if (typeof window !== "undefined") {
    const component = (() => {
      const topLevel = info.componentStack.split("(")[0].split(" ");
      while (topLevel.length > 0) {
        const top = topLevel.pop();
        if (top && top.length > 0) {
          return top;
        }
      }
      return "Unknown";
    })();
    window.analytics.track("FE Error", { component, message: error.message });
  }
};

export default trackError;
