/**
 * This component exists only for contentful images
 */

import { useEffect, useState } from "react";

import { appendParamsToUrl } from "#components/contentful/ResponsiveImage/ResponsiveImage";
import {
  ImageFormat,
  ImageOpti,
  ImgParams,
} from "#components/contentful/ResponsiveImage/ResponsiveImage.types";
import {
  breakpoints,
  isDisplayingTo,
} from "#v2-components/molecules/breakpoint";

/** width and height are mandatory. Do not change this. Its fine
 * if you modify the width/height in CSS to whatever - it must be
 * set on these images to minimze CLS
 */
interface SharedProps
  extends Omit<React.ComponentPropsWithoutRef<"img">, "width" | "height"> {
  src: string;
  alt: string;
  onLoad?: () => void /* if we start seeing a lot of props that need to go on the <picture> element, set up pictureProps */;
  pictureId?: string;
  contentfulImageParams?: ImgParams;
}
interface SimpleSizing {
  width: number;
  height: number;
}
interface ResponsiveSizing {
  desktopWidth: number;
  desktopHeight: number;
  mobileWidth: number;
  mobileHeight: number;
}
type SimpleContentfulImageProps = SharedProps & SimpleSizing;
type ResponsiveContentfulImageProps = SharedProps & ResponsiveSizing;

type ContentfulImageProps =
  | SimpleContentfulImageProps
  | ResponsiveContentfulImageProps;

export const ContentfulImage = (props: ContentfulImageProps) => {
  const [isSmall, setIsSmall] = useState(isDisplayingTo(breakpoints.S));
  useEffect(() => {
    function handleResize() {
      setIsSmall(isDisplayingTo(breakpoints.S));
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const {
    desktopWidth,
    desktopHeight,
    mobileWidth,
    mobileHeight,
    pictureId,
    src,
    alt,
    onLoad,
    contentfulImageParams,
    ...imgProps
  } = (() => {
    // eslint-disable-next-line react/destructuring-assignment
    if ((props as SimpleContentfulImageProps).width != null) {
      /* eslint-disable @typescript-eslint/no-shadow */
      const {
        width,
        height,
        src,
        alt,
        onLoad,
        pictureId,
        contentfulImageParams,
        ...imgProps
      } = props as SimpleContentfulImageProps;
      const desktopHeight = height || contentfulImageParams?.h;
      const mobileHeight = height || contentfulImageParams?.h;
      const desktopWidth = width || contentfulImageParams?.w;
      const mobileWidth = width || contentfulImageParams?.w;
      return {
        pictureId,
        desktopHeight,
        desktopWidth,
        mobileHeight,
        mobileWidth,
        src,
        alt,
        onLoad,
        contentfulImageParams,
        ...imgProps,
      };
    }
    const {
      desktopHeight,
      desktopWidth,
      mobileHeight,
      mobileWidth,
      contentfulImageParams,
    } = props as ResponsiveContentfulImageProps;
    /* eslint-enable @typescript-eslint/no-shadow */
    return {
      ...props,
      desktopHeight: desktopHeight || contentfulImageParams?.h,
      desktopWidth: desktopWidth || contentfulImageParams?.w,
      mobileHeight: mobileHeight || contentfulImageParams?.h,
      mobileWidth: mobileWidth || contentfulImageParams?.w,
    } as ResponsiveContentfulImageProps;
  })();

  if (src.indexOf("ctfassets") === -1) {
    // eslint-disable-next-line no-console
    console.warn(
      `contentful image widget attempting to display non-contentful image ${src}`,
    );
  }

  if (contentfulImageParams) {
    // MPR, 2023/10/11: these properties are not configurable in this component.
    /* eslint-disable no-unused-expressions */
    delete contentfulImageParams.fm;
    delete contentfulImageParams.fl;
    delete contentfulImageParams.h;
    delete contentfulImageParams.w;
    /* eslint-enable no-unused-expressions */
  }

  /* MPR, 2023/10/11: We could use the <source>'s `media` property to do these
   * queries for us. This would require more testing than I wanted to do, however
   * particularly given that this will only be an optimization for users that
   * shift from desktop<->mobile views */
  const width = isSmall ? mobileWidth : desktopWidth;
  const height = isSmall ? mobileHeight : desktopHeight;
  const imgParamsWebp: ImgParams = {
    fm: ImageFormat.webp,
    w: width,
    h: height,
    ...contentfulImageParams,
  };
  const imgParamsJpg: ImgParams = {
    fm: ImageFormat.jpg,
    fl: ImageOpti.progressive,
    w: width,
    h: height,
    ...contentfulImageParams,
  };

  if (src.includes(".svg")) {
    return (
      <img width={width} height={height} src={src} {...imgProps} alt={alt} />
    );
  }

  return (
    <picture id={pictureId} onLoad={onLoad}>
      <source
        srcSet={appendParamsToUrl(src, imgParamsWebp, !isSmall)}
        width={width}
        height={height}
        type="image/webp"
      />
      <source
        srcSet={appendParamsToUrl(src, imgParamsJpg, !isSmall)}
        width={width}
        height={height}
        type="image/jpg"
      />
      <img width={width} height={height} src="" {...imgProps} alt={alt} />
    </picture>
  );
};
