import NumberScroller from "number-scroller";
import { useEffect, useState } from "react";

import { Logo } from "#components/base/Icon/Icon";
import { centsToDollarString } from "#util/number/number";

import s from "./StandardDeferredInfographic.module.scss";
import { StandardDeferredInfographicFields } from "./StandardDeferredInfographic.types";

const StandardDeferredInfographic = ({
  fields,
}: {
  fields: StandardDeferredInfographicFields;
}) => {
  const {
    imageUrl,
    footer,
    standardMonthlyPayment,
    deferredMonthlyPayment,
    totalDeferred,
    standardLifetimeInterest,
    deferredLifetimeInterest,
    months,
    totalPayments,
  } = fields;
  const [isDeferred, setIsDeferred] = useState<boolean>(true);
  const [deferredAmount, setDeferredAmount] = useState<number>(totalDeferred);
  const [monthlyPayment, setMonthlyPayment] = useState<number>(
    deferredMonthlyPayment,
  );
  const [lifetimeInterest, setLifetimeInterest] = useState<number>(
    deferredLifetimeInterest,
  );

  const denominator = totalPayments + lifetimeInterest;
  const [width, setWidth] = useState<number>(
    ((monthlyPayment * months - deferredAmount) / denominator) * 100,
  );

  const deferredWidth =
    (totalDeferred / (totalPayments + deferredLifetimeInterest)) * 100;

  useEffect(() => {
    if (isDeferred) {
      setDeferredAmount(totalDeferred);
      setMonthlyPayment(deferredMonthlyPayment);
      setLifetimeInterest(deferredLifetimeInterest);
    } else {
      setDeferredAmount(0);
      setMonthlyPayment(standardMonthlyPayment);
      setLifetimeInterest(standardLifetimeInterest);
    }

    setWidth(((monthlyPayment * months - deferredAmount) / denominator) * 100);
  }, [isDeferred, deferredAmount, monthlyPayment, lifetimeInterest]);

  return (
    <div className={s.infographicContainer}>
      <div className={s.main}>
        <div className={s.pill}>
          <div
            className={`${s.deferredContainer} ${
              isDeferred ? s.active : s.inactive
            }`}
            role="button"
            onClick={() => setIsDeferred(true)}
            tabIndex={0}
            onKeyDown={() => setIsDeferred(true)}
          >
            <div
              className={`${s.deferredBackground} ${
                isDeferred ? s.active : s.inactive
              }`}
            />
            <div
              className={`${s.deferred} ${isDeferred ? s.active : s.inactive}`}
            >
              <Logo size={16} />
              Deferred
            </div>
          </div>
          <div
            className={`${s.standardContainer} ${
              isDeferred ? s.inactive : s.active
            }`}
            role="button"
            onClick={() => setIsDeferred(false)}
            tabIndex={0}
            onKeyDown={() => setIsDeferred(false)}
          >
            <div
              className={`${s.standardBackground} ${
                isDeferred ? s.inactive : s.active
              }`}
            />
            <div
              className={`${s.standard} ${isDeferred ? s.inactive : s.active}`}
            >
              Standard
            </div>
          </div>
        </div>
        <img src={imageUrl} alt="Vehicle" />
        <div className={s.breakdownBar}>
          <div
            className={s.monthlyPayment}
            style={{
              left: 0,
              width: `${width}%`,
              maxWidth: `${width}%`,
              background: "#23A0C8",
              borderRadius: "4px 0 0 4px",
            }}
          />
          <div
            className={s.deferredPayment}
            style={{
              left: `${width}%`,
              width: `${isDeferred ? deferredWidth : 0}%`,
              maxWidth: `${isDeferred ? deferredWidth : 0}%`,
              background: "#9C44FB",
            }}
          />
        </div>
        <div className={s.termLength}>
          <div className={s.leftDashes} />
          <span>{months} month loan term</span>
          <div className={s.rightDashes} />
        </div>
        <div className={s.breakdown}>
          <div className={s.monthlyPaymentsBreakdown}>
            <div className={s.box} />
            <p>
              {months} monthly payments of{" "}
              <NumberScroller
                step={2.49}
                timeout={500}
                from={
                  isDeferred ? deferredMonthlyPayment : standardMonthlyPayment
                }
                to={
                  isDeferred ? deferredMonthlyPayment : standardMonthlyPayment
                }
                toLocaleStringProps={[
                  "en-US",
                  {
                    currency: "USD",
                    style: "currency",
                  },
                ]}
              />
            </p>
          </div>
          {isDeferred && (
            <div className={s.deferredPaymentsBreakdown}>
              <div className={s.box} />
              <p>
                Total deferred payment{" "}
                {centsToDollarString(totalDeferred * 100)}
              </p>
            </div>
          )}
          <div className={s.lifetimeInterestBreakdown}>
            <div className={s.box} />
            <p>
              Lifetime loan total interest{" "}
              {isDeferred
                ? centsToDollarString(deferredLifetimeInterest * 100)
                : centsToDollarString(standardLifetimeInterest * 100)}
            </p>
          </div>
        </div>
      </div>
      <p className={s.disclaimer}>{footer}</p>
    </div>
  );
};

export default StandardDeferredInfographic;
