import classNames from "classnames";

import { ArrowRight } from "#components/base/Icon/Icon";
import Button from "#v2-components/molecules/Button/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "#v2-components/molecules/Button/Button.types";
import { ContentfulImage } from "#v2-components/molecules/ContentfulImage";

import s from "./LinkedTileCard.module.scss";
import {
  LinkedTileCardFields,
  LinkedTileCardType,
  LinkedTileCardVariants,
  PrimaryButtonAlignments,
} from "./LinkedTileCard.types";

const PersonalPromotionalCard = ({
  fields,
}: {
  fields: LinkedTileCardFields;
}) => {
  const {
    title,
    body,
    image,
    primaryButtonText,
    primaryButtonUrl,
    secondaryButtonText,
    secondaryButtonUrl,
    type,
  } = fields;

  return (
    <div className={`${s.promotional} ${s.linkedTileCard}`}>
      <div className={s.mainContent}>
        <div className={s.cardDetails}>
          <h3>{title}</h3>
          <p>{body}</p>
        </div>
        <div className={s.buttonsContainer}>
          <a
            href={primaryButtonUrl}
            target="_blank"
            rel="noreferrer"
            className={s.primaryButton}
          >
            <Button
              variant={
                type === LinkedTileCardType.Personal
                  ? ButtonVariant.PSEUDO_LINK
                  : ButtonVariant.PSEUDO_LINK_PINK
              }
              data-cy={primaryButtonText || "primary-button"}
            >
              {primaryButtonText} <ArrowRight />
            </Button>
          </a>
          {secondaryButtonUrl && (
            <>
              <div className={s.centerDivider} />
              <a href={secondaryButtonUrl} target="_blank" rel="noreferrer">
                <Button
                  variant={ButtonVariant.DARK_OUTLINE}
                  forceSize={ButtonSize.SMALL}
                  data-cy={secondaryButtonText || "secondary-button"}
                >
                  {secondaryButtonText}
                </Button>
              </a>
            </>
          )}
        </div>
        <div className={s.imageContainer}>
          <picture id={image.fields.title}>
            <source srcSet={image.fields.file.url} type="image/webp" />
            <source srcSet={image.fields.file.url} type="image/png" />
            <img alt={image.fields.title} src={image.fields.file.url} />
          </picture>
        </div>
      </div>
    </div>
  );
};

const LinkedTitleCardV5 = ({ fields }: { fields: LinkedTileCardFields }) => {
  return (
    <div className={classNames(s.v5wrapper)}>
      <h3>{fields.title}</h3>
      <p>{fields.body}</p>
      <Button
        data-cy="v5-linked-title-card-cta-button"
        variant={ButtonVariant.DARK_FILLED}
        onClick={() => {
          document.location = fields.primaryButtonUrl;
        }}
      >
        {fields.primaryButtonText}
      </Button>
    </div>
  );
};

const LinkedTileCard = ({ fields }: { fields: LinkedTileCardFields }) => {
  const {
    title,
    body,
    image,
    mobileImage,
    variant,
    primaryButtonAlignment,
    primaryButtonText,
    primaryButtonUrl,
    type,
    templateVersion,
  } = fields;

  if (templateVersion === 5) {
    return <LinkedTitleCardV5 fields={fields} />;
  }

  const variantClassName = (() => {
    switch (variant) {
      case LinkedTileCardVariants.SidebySide:
        return s.sideBySide;
      case LinkedTileCardVariants.AboveAndBelow:
        return s.aboveAndBelow;
      case LinkedTileCardVariants.Promotional:
        return s.promotional;
      case LinkedTileCardVariants.ImageFocus:
        return s.imageFocus;
      default:
        return "";
    }
  })();

  const buttonVariantByType = (() => {
    switch (true) {
      case type === LinkedTileCardType.Personal:
        return ButtonVariant.PSEUDO_LINK;
      case type === LinkedTileCardType.Business &&
        variant === LinkedTileCardVariants.Promotional:
        return ButtonVariant.DARK_FILLED;
      case type === LinkedTileCardType.Business:
        return ButtonVariant.PSEUDO_LINK_PINK;
      default:
        return ButtonVariant.PSEUDO_LINK;
    }
  })();

  const buttonAlignmentClass = (() => {
    switch (primaryButtonAlignment) {
      case PrimaryButtonAlignments.Center:
        return s.center;
      case PrimaryButtonAlignments.Start:
        return s.alignLeft;
      case PrimaryButtonAlignments.End:
        return s.alignRight;
      default:
        return s.alignRight;
    }
  })();

  if (
    variant === LinkedTileCardVariants.Promotional &&
    type === LinkedTileCardType.Personal
  ) {
    return <PersonalPromotionalCard fields={fields} />;
  }
  return (
    <a
      href={primaryButtonUrl}
      target="_blank"
      rel="noreferrer"
      className={`${variantClassName} ${s.linkedTileCard}`}
    >
      <div className={s.imageContainer}>
        <ContentfulImage
          src={image.fields.file.url}
          desktopWidth={920}
          desktopHeight={502}
          mobileWidth={752}
          mobileHeight={280}
          alt={image.fields.title}
        />
      </div>
      {mobileImage && (
        <div className={s.mobileImageContainer}>
          <ContentfulImage
            src={mobileImage.fields.file.url}
            desktopWidth={920}
            desktopHeight={502}
            mobileWidth={350}
            mobileHeight={275}
            alt={image.fields.title}
          />
        </div>
      )}
      <div className={s.mainContent}>
        <div className={s.cardDetails}>
          <h3>{title}</h3>
          {body && <p>{body}</p>}
        </div>
        <div className={`${s.buttonContainer} ${buttonAlignmentClass}`}>
          <div className={s.primaryButton}>
            <Button
              variant={buttonVariantByType}
              data-cy={primaryButtonText || "primary-button"}
            >
              {primaryButtonText} <ArrowRight />
            </Button>
          </div>
        </div>
      </div>
    </a>
  );
};

export default LinkedTileCard;
