/** ******************************************************************************************************
* Anonymous AB Test Store
* Author: @TenetMax
* Created: 2024/5/8
* Description:
A store for globally tracking anonymous (e.g. unauthenticated) A/B tests. These tests are configured
in contentful using the A/B Test Option component.

******************************************************************************************************* */

/* eslint-disable no-param-reassign */
import Cookies from "js-cookie";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { trackVariant } from "#analytics/trackVariant";

export interface Store {
  ready: boolean;
  experiments: string[];
  options: Record<string, string[]>;
  optionWeights: Record<string, number>;
  setReady: () => void;
  registerVariant: (
    experiment: string,
    variant: string,
    weight: number,
  ) => void;
  shouldShowVariant: (experiment: string, variant: string) => boolean;
}

const useAnonymousExperimentStore = create<Store>()(
  immer((set, get) => ({
    ready: false,
    experiments: [],
    options: {},
    optionWeights: {},
    setReady: () => {
      set((state) => {
        state.ready = true;
      });
    },
    registerVariant: (experiment, variant, weight) => {
      const variantKey = `${experiment}-${variant}`;
      set((state) => {
        state.experiments.push(experiment);
        if (!state.options[experiment]) {
          state.options[experiment] = [];
        }
        state.options[experiment].push(variant);
        state.optionWeights[variantKey] = weight;
      });
    },
    shouldShowVariant: (experiment, variant) => {
      const { experiments, options, optionWeights } = get();
      if (experiments.indexOf(experiment) === -1) {
        console.warn(
          `Experiment ${experiment} not registered. No variant will display.`,
        );
        return false;
      }
      /* MPR, 2024/5/8: Ok so this is not super clear. Here's what's happening: */
      const variantKey = `${experiment}-${variant}`;
      const variants = options[experiment];
      // sum all the weights, regardless of what they are (e.g [1,2,1], [90, 10])
      const totalWeight = variants.reduce(
        (acc, v) => acc + optionWeights[`${experiment}-${v}`],
        0,
      );
      // find where the variant is in the list of variants
      const variantIndex = variants.indexOf(variant);
      // sum the weights of all the variants before the current one
      const variantWeights = variants.map(
        (v) => optionWeights[`${experiment}-${v}`],
      );
      const weightedVariantIndex = variantWeights
        .slice(0, variantIndex)
        .reduce((v, a) => v + a, 0);
      // this gives us a range. so if we have [1,2,1] and we're looking at the second variant,
      // we want to know if we're in the range [1,3) in an option space of [a, b, b, c]
      const variantWeight = optionWeights[variantKey];
      const bucket =
        Cookies.get("bucket") != null ? +(Cookies.get("bucket") as string) : 0;
      // we mod the sum of all weights against the bucket (0 - 10000) to get a position in the range
      const resultPosition = bucket % totalWeight;

      // our answer will thus be one of the options, distributed by weight
      const shouldShowVariant =
        resultPosition >= weightedVariantIndex &&
        resultPosition < weightedVariantIndex + variantWeight;

      if (shouldShowVariant) {
        trackVariant(experiment, variant, bucket);
      }
      return shouldShowVariant;
    },
  })),
);

export default useAnonymousExperimentStore;
