import Button from "#components/base/Button/Button";
import { ArrowRight, Email } from "#components/base/Icon/Icon";
import Link from "#components/base/Link/Link";

import styles from "./ReadyToApplyCard.module.scss";

const ReadyToApplyCard = () => {
  return (
    <div className={styles.readyToApplyContainer}>
      <ReadyToApplyContent />
      <SupportContactCard />
    </div>
  );
};

const ReadyToApplyContent = () => {
  return (
    <div className={styles.leftContainer}>
      <span className={styles.readyToApplyHeader}>Ready to apply?</span>
      <span className={styles.readyToApplyDesc}>
        Take the next step in your EV buying journey today.
      </span>
      <div className={styles.buttons}>
        <Button
          className={styles.leftButton}
          as="a"
          href="/new-loan/intent/?applyConfig=STANDARD_NEW_LOAN"
          id="get-started-button"
          aria-label="new loan"
          data-cy="start-new-loan"
        >
          New loan
          <ArrowRight size={16} />
        </Button>
        <Button
          className={styles.rightButton}
          as="a"
          href="/refinance/vehicle-info/?applyConfig=STANDARD_REFINANCE"
          id="refi-apply-button"
          aria-label="refinance"
          data-cy="start-new-refinance"
        >
          Refinance my EV
          <ArrowRight size={16} />
        </Button>
      </div>
    </div>
  );
};

const SupportContactCard = () => {
  return (
    <div className={styles.SupportContactCardContainer}>
      <span className={styles.supportHeader}> Get in touch </span>
      <span className={styles.supportRow}>
        <div className={styles.frame}>
          <Email className={styles.icon} />
        </div>
        <Link
          href="mailto:support@tenet.com"
          target="_blank"
          variant="inherit"
          aria-label="support email"
        >
          support@tenet.com
        </Link>
      </span>
    </div>
  );
};

export const ContactUs = () => {
  return (
    <div className={styles.contactUs}>
      <ReadyToApplyCard />
    </div>
  );
};

export default ReadyToApplyCard;
