/** ******************************************************************************************************
* Contentful A/B Test Option
* Author: @Tenetmax
* Created: 2024/5/6
* Description:
A contentful component representing an A/B Test Option. Contains an experiment name, a bucket name, and
a bucket weight. Taken together, this allows us to determine which option to display.

How to use this template:
1) If you are creating a simple component, use the regions provided.
2) If you are creating a complex component, uncomment the other files
(e.g. data, types, etc) and delete the corresponding region. Mixing and
matching is fine.

******************************************************************************************************* */

// #region ▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰••● Imports ●••▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰▰ //
import noop from "lodash/noop";
import React, { useEffect, useState } from "react";

import { parseGenericPage } from "#components/pages/contentful/GenericPage/GenericPage";
import LoadingState from "#components/partial/LoadingState/LoadingState";
import useAnonymousExperimentStore from "#store/local.anonymousABTest";

import { ABTestOptionFields } from "./types";

const ABTest = ({
  fields,
  onHeroVisibilityDetected,
}: {
  fields: ABTestOptionFields;
  onHeroVisibilityDetected?: () => void;
}) => {
  const { shouldShowVariant, registerVariant } = useAnonymousExperimentStore();
  const [registered, setRegistered] = useState(false);

  useEffect(() => {
    registerVariant(fields.experiment, fields.variant, fields.weight);
    setRegistered(true);
  }, []);

  if (!registered) {
    return <LoadingState />;
  }

  const active = shouldShowVariant(fields.experiment, fields.variant);

  if (!active) {
    return null;
  }

  return (
    <>{parseGenericPage(fields.pageSections, noop, onHeroVisibilityDetected)}</>
  );
};

export default ABTest;
