/**
 * Error Boundary
 * Wraps components to provide a fallback UI in case of an error
 * React Docs https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 * Next Docs https://nextjs.org/docs/advanced-features/error-handling
 *
 * This component is just a wrapper around https://github.com/bvaughn/react-error-boundary to provide
 * simple defaults to allow us to introduce it to many compnenets quickly. All of the props will be passed
 * through to react-error-boundary, so follow it's documentation for usage and customization of errors.
 *
 * Why not use the already existing Sentry boundary? https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary/
 * We could have, considered this option. However, we use the nextjs sentry integration, not the react sentry integration.
 * We could switch them, but that would be more work, and Sentry already works fine. The disavantage
 * of this choice is it is almost always better to use components managed by a company when available, for
 * extended support guarentees. However, React themselves reccommend the component we are using, and frankly
 * I like its API just a little better.
 */
import ExclamationTriangleIcon from "@heroicons/react/24/solid/ExclamationTriangleIcon";
import React, { ReactNode } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps,
} from "react-error-boundary";

import trackError from "#components/util/trackError";

import s from "./ErrorBoundary.module.scss";

type BoundaryProps = Omit<ErrorBoundaryProps, "children">;

type FullBoundaryProps = {
  children: React.ReactNode;
  fallback?:
    | React.ReactElement<
        unknown,
        // eslint-disable-next-line @typescript-eslint/ban-types
        string | React.FunctionComponent<{}> | typeof React.Component
      >
    | null
    | undefined;
  fallbackRender?: (errors?: never) => React.ReactNode;
  boundaryProps?: BoundaryProps;
};

const ErrorBoundary = ({
  children,
  fallback,
  fallbackRender,
  ...boundaryProps
}: FullBoundaryProps) => {
  /* MPR, 2023/5/1: Initial approach is to only show the default if
   * otherwise treat it as a passthrough. I may come to hate this decision.
   * Only time will tell.
   */
  if (!fallback && !fallbackRender) {
    // display default
    return (
      <ReactErrorBoundary
        onError={(error, info) => {
          // track in segment
          trackError(error, info);
        }}
        fallbackRender={() => {
          return (
            <div className={s.wrapper}>
              <div className={s.errorContainer}>
                <span>
                  <ExclamationTriangleIcon width={24} />
                </span>
                <span>We&apos;ve run into an issue</span>
                <span className={s.suberror}>
                  If this problem persists, please reach out to support at
                  support@tenet.com
                </span>
              </div>
            </div>
          );
        }}
      >
        {children}
      </ReactErrorBoundary>
    );
  }
  if (fallbackRender) {
    return (
      <ReactErrorBoundary
        fallbackRender={fallbackRender as () => ReactNode}
        {...boundaryProps}
      >
        {children}
      </ReactErrorBoundary>
    );
  }
  return (
    <ReactErrorBoundary fallbackRender={() => fallback} {...boundaryProps}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;

export function withErrorBoundary<Props extends Record<string, unknown>>(
  Component: React.ComponentType<Props>,
  // errorBoundaryProps?: ErrorBoundaryProps,
) {
  // eslint-disable-next-line func-names
  return function (props: Props) {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
}
