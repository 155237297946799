export enum CustomContentModelType {
  /**
   * CUSTOM CONTENT MODELS
   */

  // Generic (v5) Page Sections
  PageContent = "pageContent",
  ButtonLink = "navigator",
  ResponsiveImage = "responsiveImage",
  Theme = "theme",
  Copy = "copy",
  RichText = "richText",
  Metadata = "metadata",
  ABTest = "abTestOption",

  // Non-Generic (v4) Page Sections
  HeaderSection = "headerSection",
  FooterSection = "footerSection",
  DetailsSection = "detailsSection",
  CalculatorSection = "calculatorSection",
  StackablesSection = "stackablesSection",
  FeatureComparisonSection = "featureComparisonSection",
  ContactUsSection = "contactUsSection",
  PartnersAndQuotesSection = "partnersAndQuotesSection",
  CommitmentSection = "commitmentSection",
  FAQSection = "faqSection",
  InTheNewsSection = "inTheNewsSection",
  TestimonialsSection = "testimonialsSection",
  TitleSection = "titleSection",
  JobOpeningsSection = "jobOpeningsSection",
  RoleDescriptionSection = "roleDescriptionSection",
  TeslaHero = "teslaHeroSection",
  TeamCardsSection = "teamCardsSection",
  StackableStepsSection = "stackableStepsSection",
  CommonQuestionsSection = "commonQuestionsSection",
  BlenderSection = "blender",
  LargeImageSection = "largeImageSection",
  HomeHeroSection = "homeHero",
  LinkedTileCard = "linkedTileCard",
  SplitLinkedCard = "splitLinkedCard",
  HowItWorks = "howItWorks",
  Navbar = "navbar",
  AddonsAndBenefits = "addonsAndBenefits",
  RichTextComponent = "richTextComponent",

  // Add on page
  AddOnPage = "addOnPage",

  // Make page
  MakePage = "makePage",

  // Initially built for Partner
  SectionPartnerHero = "partnerHeroSection",
  BannerSection = "bannerSection",
  IconBannerSection = "iconBannerSection",

  // unused in top level generic page
  NewsComponent = "newsComponent",
  Stackable = "stackablePaneComponent",
  DescriptionPanelComp = "descriptionPanel",

  // Blog
  BlogSection = "blogSection",
  BlogCardSection = "blogCardsSection",

  // Careers Sections
  GetInTouchCareersCard = "getInTouchCareersCard",

  Divider = "divider",
  EmailSubscriptionBanner = "emailSubscriptionBanner",

  // Smart charging components
  AccordionSection = "accordionSection",
  ValuePropSection = "valuePropSection",
  HeroWithIcon = "heroWithIcon",
  SmallSection = "smallSection",
  LargeSection = "largeSection",

  // Error (never returned from contentful)
  Error = "error",
  // Mock (never returned from contentful)
  Mock = "mock",
}

export interface ContentfulCustomNode<T, ID extends CustomContentModelType> {
  contentTypeID?: ID; // This is not available, but added later for type narrowing
  fields: T;
  metadata: { tags: [] };
  sys: {
    id: string;
    type: "Entry";
    createdAt: string;
    updatedAt: string;
    contentType: {
      sys: {
        type: "Link";
        linkType: "ContentType";
        id: ID;
      };
    };
  };
}

export interface ContentfulAssetNode {
  fields: {
    description: string;
    title: string;
    file: {
      contentType: string;
      fileName: string;
      url: string;
      details: {
        size: number;
        image: {
          width: number;
          height: number;
        };
      };
    };
  };
  metadata: { tags: [] };
  sys: {
    id: string;
    type: "Asset";
    createdAt: string;
    updatedAt: string;
  };
}
