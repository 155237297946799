import { ContentfulImage } from "#v2-components/molecules/ContentfulImage";

import s from "./ResponsiveImage.module.scss";
import {
  ImgParams,
  ResponsiveImageFields,
  ResponsiveImageProps,
} from "./ResponsiveImage.types";

/**
 * appends img optimization parameters to the end of a contentful image url
 * @param {string} url contentful image url
 * @param {string} params conentful image params
 * @returns an optimized contentful url
 */
export const appendParamsToUrl = (
  url: string,
  params?: ImgParams,
  isDesktop?: boolean,
) => {
  const paramEntries = Object.entries(params || {});
  const paramString =
    paramEntries.length === 0
      ? ""
      : paramEntries.reduce((prev, [key, value]) => {
          let ordinal;
          // MPR, 2024/05/15: Some of our images are looking blurry at their exact size. I think this
          // is an artifact of the way contentful resizes in a lossy way. I'm compensating by loading the
          // images slightly larger than they need to be.
          if (key === "w" || key === "h") {
            if (isDesktop) {
              ordinal = Math.floor(+value);
            }
          }
          if (value) {
            if (!prev) {
              return `?${key}=${ordinal == null ? value : ordinal}`;
            }
            return `${prev}&${key}=${ordinal == null ? value : ordinal}`;
          }
          return prev;
        }, "");
  return `${url}${paramString}`;
};

const ResponsiveImage = ({
  fields,
  componentProps,
}: {
  fields: ResponsiveImageFields;
  componentProps?: ResponsiveImageProps;
}) => {
  const imgParams = componentProps?.imgParams;
  /* MPR, 2023/10/11: I'm being lazy using two contentful images here. This component
   * can take care of this itself, but I do not want to check every page for style
   * adjustments */
  /* MPR, 2024/5/14: Its also stupid because these are now the same size, which makes no sense
    for this to be remotely useful we need to be able to pass different sizes when they are known.
  */
  return (
    <>
      <ContentfulImage
        loading="lazy"
        className={s.desktop}
        src={fields.desktopImage.fields.file.url}
        width={imgParams?.w || 768}
        height={imgParams?.h || 512}
        alt={fields.desktopImage.fields.title}
        contentfulImageParams={imgParams}
      />
      <ContentfulImage
        loading="lazy"
        className={s.mobile}
        src={
          fields.mobileImage != null
            ? fields.mobileImage.fields.file.url
            : fields.desktopImage.fields.file.url
        }
        width={imgParams?.w || 768}
        height={imgParams?.h || 512}
        alt={
          fields.mobileImage != null
            ? fields.mobileImage.fields.title
            : fields.desktopImage.fields.title
        }
        contentfulImageParams={imgParams}
      />
    </>
  );
};

export default ResponsiveImage;
