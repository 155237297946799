import { Asset } from "contentful";

import {
  ContentfulCustomNode,
  CustomContentModelType,
} from "#components/contentful/contentful.custom.types";

export enum LinkedTileCardType {
  Personal = "personal",
  Business = "business",
}

export enum LinkedTileCardVariants {
  SidebySide = "side by side",
  AboveAndBelow = "above and below",
  Promotional = "promotional",
  ImageFocus = "image focus",
}

export enum PrimaryButtonAlignments {
  Start = "Start",
  End = "End",
  Center = "Center",
}

export type LinkedTileCardFields = {
  templateVersion?: number;
  body?: string;
  image: Asset;
  mobileImage: Asset;
  primaryButtonAlignment: PrimaryButtonAlignments;
  primaryButtonText?: string;
  primaryButtonUrl: string;
  secondaryButtonText?: string;
  secondaryButtonUrl?: string;
  title: string;
  type: LinkedTileCardType;
  variant: LinkedTileCardVariants;
};

export type LinkedTileCard = ContentfulCustomNode<
  LinkedTileCardFields,
  CustomContentModelType.LinkedTileCard
>;
