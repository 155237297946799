import styles from "./breakpoint.module.scss";

export const breakpoints = {
  XS: styles.xs,
  S: styles.s,
  "S-M": styles["s-m"],
  M: styles.m,
  L: styles.l,
  XL: styles.xl,
};

export const isDisplayingFrom = (width: string) => {
  if (typeof window !== "undefined") {
    return window.matchMedia(`(min-width: ${width})`).matches;
  }
  return false;
};

export const isDisplayingTo = (width: string) => {
  if (typeof window !== "undefined") {
    return window.matchMedia(`(max-width: ${width})`).matches;
  }
  return false;
};
