import React from "react";

import { AddonsAndBenefitsFields } from "#components/contentful/contentful-v2/AddonsAndBenefits/AddonsAndBenefits.types";
import LinkedTileCard from "#components/contentful/LinkedTileCard/LinkedTileCard";
import StandardDeferredInfographic from "#v2-components/molecules/StandardDeferredInfographic/StandardDeferredInfographic";
import { StandardDeferredInfographicFields } from "#v2-components/molecules/StandardDeferredInfographic/StandardDeferredInfographic.types";
import SectionWrapper from "#v2-components/templates/section-wrapper/SectionWrapper";

import s from "./AddonsAndBenefits.module.scss";

const AddonsAndBenefits = ({ fields }: { fields: AddonsAndBenefitsFields }) => {
  const {
    imageUrl,
    footer,
    standardMonthlyPayment,
    deferredMonthlyPayment,
    totalDeferred,
    standardLifetimeInterest,
    deferredLifetimeInterest,
    months,
    totalPayments,
    addTopPadding,
  } = fields;
  const infoGraphicFields: StandardDeferredInfographicFields = {
    imageUrl,
    footer,
    standardMonthlyPayment,
    deferredMonthlyPayment,
    totalDeferred,
    standardLifetimeInterest,
    deferredLifetimeInterest,
    months,
    totalPayments,
  };
  return (
    <SectionWrapper>
      <div
        className={`${s.container} ${addTopPadding ? s.paddingTop : undefined}`}
      >
        <div className={s.half}>
          <div className={s.text}>
            <h1>{fields.leftHeader}</h1>
            <h2>{fields.leftSubheader}</h2>
          </div>
          <div style={{ width: "100%" }}>
            <StandardDeferredInfographic fields={infoGraphicFields} />
          </div>
        </div>
        <div className={s.half}>
          <div className={s.text}>
            <h1>{fields.rightHeader}</h1>
            <h2>{fields.rightSubheader}</h2>
          </div>
          <div className={s.cards}>
            <LinkedTileCard fields={fields.tallCard.fields} />
            <LinkedTileCard fields={fields.topCard.fields} />
            <LinkedTileCard fields={fields.bottomCard.fields} />
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default AddonsAndBenefits;
