import classNames from "classnames";
import React from "react";

import s from "./SectionWrapper.module.scss";

const SectionWrapper = ({
  children,
  backgroundColor,
  fullWidth,
}: {
  children: JSX.Element;
  backgroundColor?: string;
  fullWidth?: boolean;
}) => {
  return (
    <div className={s.container} style={{ backgroundColor }}>
      <div
        className={classNames(s.section, {
          [s.fullWidth]: fullWidth,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default SectionWrapper;
