export const trackVariant = (
  experiment: string,
  variant: string,
  bucket: number,
) => {
  // eslint-disable-next-line no-console
  console.info(
    "displayed experiment",
    experiment,
    "variant",
    variant,
    "bucket",
    bucket,
  );
  const experimentTrackedKey = `aabt-${experiment}-${variant}-${bucket}`;
  if (!localStorage.getItem(experimentTrackedKey)) {
    window.analytics?.track("anonymous-a-b-test", {
      experiment,
      variant,
      bucket,
    });
    localStorage.setItem(experimentTrackedKey, "true");
  }
};
