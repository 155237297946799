import Cookies, { CookieAttributes } from "js-cookie";
import { useCallback, useState } from "react";

/**
 * This hook will take the name and default value of a cookie, if the cookie exists then
 * it will return that cookies value as "value", if not it will create a cookie with the
 * default value.
 * @param name the name of the cookie
 * @param defaultValue the default value of the cookie
 * @returns value, updateCookie, deleteCookie
 */

export const useCookie = (name: string, defaultValue: string) => {
  const [value, setValue] = useState<string | null>(() => {
    const cookie = Cookies.get(name);
    if (cookie) {
      return cookie;
    }
    Cookies.set(name, defaultValue);
    return defaultValue;
  });

  const updateCookie = useCallback(
    (newValue: string, options?: CookieAttributes) => {
      Cookies.set(name, newValue, options);
      setValue(newValue);
    },
    [name],
  );

  const deleteCookie = useCallback(() => {
    Cookies.remove(name);
    setValue(null);
  }, [name]);

  return { value, updateCookie, deleteCookie };
};

// PS - This is not custom code, I stole it from the interweb and turned it into typescript.
